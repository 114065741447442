@import "./font";
@import "./reset";
@import "./variable";

html{
    font-family: /*'Montserrat',*/'IBM Plex Sans' , Arial, sans-serif !important;
    line-height: 1.15;
    text-size-adjust: 100%;
    font-size: 14px;
}

.mb10{
    margin-bottom: 10px;
}

.form-bloc {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .date-select{
        display: flex;
        justify-content: space-between;
    }

    input, select{
        border: solid 1px #707070;
        width: 100%;
        padding: 0.5rem;
    }
}

.w-100{
    width: 100%;
}

.w-50{ width: 50%;}

.btn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-width: 80px;
    min-height: 24px;
    line-height: 24px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
    background: #FAFAFA;
    color: #000;
    margin-right: 24px;

    svg{
        width: 14px;
        margin-right: 5px;
    }

    &:hover{
        background: #FFF;
    }


    &.btn-pri{
        background-color: $color-3;
        color: #000;

        &:hover{
            background-color: #ffe373;
        }
    }

    &.btn-sec{
        background-color: $color-2;
        color: white;
    }
}

.alert{
    display: flex;
    font-size: .875rem;
    padding: .5rem;

    i{
       font-size: 3rem;
        margin-right: 1rem; 
    }

    &.alert-info{
        border: solid 1px #707070;
        background: #F4F4F4;
        color: #222222;
    }
}

.forgot{
    margin: .5em 0;
    display: flex;
    justify-content: flex-end;

    a{
        color: $color-2;
    }
}


.socials{
    list-style: none;

    li{
        display: inline;
        margin-right: 8px;

        a{
            i{
                font-size: 25px;
            }

            &:hover{
                color: #F8D33A;
            }
        }
    }
}

.app{
    width: 100vw;
    height: 100vh;
    overflow: hidden auto;
    box-sizing: border-box;

    .app-inner{
        margin: 0;
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        .header{
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            width: 100%;
            padding-left: 8px;
            padding-right: 8px;
            align-items: center;
            background-color: rgba(255, 255, 255, .6);
            height: 64px;
            z-index: 99;
            transition: background-color ease-in-out .3s;

            &.opaque{
                background-color: rgba(255, 255, 255, 1);
            }

            a.header-logo{
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-left: 8px;
                margin-right: 8px;
                height: 100%;
                font-size: 14px;

                svg{
                    height: 35px;
                    width: 120px;
                }
            }

            .header-menu{
                display: flex;
                color: #707070;
                overflow: hidden;
                align-items: center;
                visibility: hidden !important;
            }

            .header-space{
                flex: 1;
            }

            .header-mobile{
                display: flex;
                margin: 0 8px;
                align-items: center;
                position: relative;
                cursor: pointer;

                &:hover{
                    color: $color-1;
                }

                svg{
                    width: 30px;
                    height: 30px;
                }
            }
        } /* Header */

        .main{
            display: flex;
            flex-direction: column;
            flex: 1;

            .hero{
                
                margin-top: -64px;

                .hero-inner{
                    margin: auto;
                    padding: 104px 16px 24px 16px;
                    background-color: #FAFAFA;
                    background-image: url('../images/banner-mobile.jpg');
                    background-size: cover;
                    background-position: center;
                    width: 100%;
                    min-height: 430px;

                    .hero-text{
                        max-width: 1200px;
                        height: 100%;
                        width: 100%;
                        margin: auto;

                        .hero-text-inner{
                            width: 100%;

                            h1{
                                font-size: 20px;
                                line-height: 28px;
                                font-weight: 500;
                                color: $color-text-dark;
                                overflow-wrap: break-word;
                            }

                            .hero-accroche{
                                font-size: 20px;
                                font-weight: 500;
                                margin-top: 16px;

                                span{
                                    display: inline-block;
                                    font-size: 28px;
                                    line-height: 36px;
                                    font-weight: 600;
                                    background: linear-gradient(to bottom,rgba(255,255,255,0) 50%,#F8D33A 50% 80%,rgba(102, 1, 1, 0) 50% 100%);
                                }
                            }

                            .hero-desc{
                                overflow-wrap: break-word;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 16px;
                                color: #474D57;
                                width: 195px;
                                margin-top: 16px;
                                margin-bottom: 32px;
                            }

                            .hero-button{
                                display: flex;
                            }
                        }
                    }
                }
            } /** hero */ 

            .find{
                background-size: cover;
                background-position: center;
                background-image: url('../images/bg_find_mobile.jpg');
            }

            .section{
                padding: 24px 0;
                

                .section-inner{
                    padding-left: 16px;
                    padding-right: 16px;
                    max-width: 1248px;

                    .section-title{
                        margin-bottom: 16px;
                        font-size: 24px;
                        line-height: 32px;
                        font-weight: 600;
                        position: relative;
                        padding-left: 25px;

                        img{
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 28px;
                        }
                    }

                    .news{
                        width: 100%;
                        display: flex;
                        flex-direction: column;

                        .news-item{
                            border-bottom: 4px solid $color-1;
                            margin-bottom: 24px;
                            padding-bottom: 8px;

                            a{
                                font-weight: 500;
                                font-size: 15px;
                                color: $color-2;
                            }
                            
                        }
                    }

                    .section-help{
                        display: flex;
                        flex-direction: column;

                        .section-help-item{
                            display: flex;
                            justify-content: flex-start;
                            margin-bottom: 16px;
                            align-items: flex-start;

                            .help-icon{
                                width: 48px;
                                margin: 10px;
                            }

                            .help-text{

                                .help-text-title{
                                    font-weight: 500;
                                    font-size: 16px;
                                    margin-bottom: 8px;
                                }

                                .help-text-content{
                                    font-weight: normal;
                                    font-size: 14px;

                                    a{
                                        color: white;
                                        font-weight: 500;
                                        
                                        &:hover{
                                            color: #F8D33A;
                                        }

                                }
                            }
                        }
                    }
                }

                .section-video{
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column-reverse;

                    .section-video-content{

                        .section-video-title{
                            font-weight: 500;
                            margin-bottom: 16px;
                            font-size: 16px;
                        }
                    }

                    .section-video-player{
                        position: relative; 
                        padding-bottom: 56.25%; 
                        padding-top: 30px; 
                        height: 0; 
                        width: 100%;
                        overflow: hidden;

                        iframe, object, embed{
                            left:0; 
                            top:0; 
                            height:100%;
                            width:100%;
                            position:absolute;
                        }
                    }
                }

                .section-find{
                        display: flex;
                        align-items: center;
                        height: 100%;
                        flex-direction: column;
                        justify-content: center;

                        .section-find-title{
                            font-size: 24px;
                            color: white;
                            margin-bottom: 24px;
                            overflow-wrap: break-word;
                            font-weight: 600;
                            text-align: center;
                        }
                }

                .section-login{
                    display: flex;
                    justify-content: space-between;
                }

                .page-bloc{
                    border: solid 1px #fad115;
                    border-bottom-width: 4px;
                    padding: 1rem;
                    margin-bottom: 2rem;
                    margin: 0 5px;

                    h3{
                        font-weight: 600;
                        margin-bottom: 1rem;
                    }

                    h4{
                        margin-top: -15px;
                        font-size: .9rem;
                        color: #707070;
                        margin-bottom: .5rem;
                    }

                    .form-incription-new-bachelier{
                        background: #d1dcd5;
                    }

                    &.register{
                        display: flex;
                        flex-direction: column;
                        background: url('../images/login_illustration.png') no-repeat bottom right;

                        .register-form{
                            width: 60%;
                            display: flex;
                            flex-direction: column;

                            .row{
                                display: flex;
                                margin-bottom: 1rem;

                                .form-bloc{
                                    margin: 0 5px;
                                }
                            }
                        }
                    }
                }

                .btn-wrap{
                    display: flex;
                    position: relative;

                    .btn {
                        font-size: 0.938rem;
                        color: #222222;
                        font-weight: 700;
                        background: #fad115;
                        padding: .5rem 1.2rem;
                        transition: color 0.2s ease-out, background-color 0.2s ease-out, border-color 0.2s ease-out;
                        margin: 10px 0 10px 0;
                        border-radius: 0.5rem;
                        cursor: pointer;

                        &:hover{
                            background: #ea1d27;
                            color: white;
                        }

                        
                    }
                }
            }

        } /**  / Main */

        
    }
}
    /** Menu mobile side-menu */
    .side-menu{
        position: fixed;
        transition: all .5s ease;

        &.opened{
            background-color: rgba(0,0,0,.3);
            position: fixed;
            display: flex;
            flex-direction: row-reverse;
            z-index: 9999;
            width: 100%;
            inset: 0px;

            .side-menu-content{
                width: 375px;
                height: 100vh;
            }
        }

        .side-menu-content{
            height: 100%;
            transition: all .5s ease;
            width: 0px;
            background-color: rgba(255, 255, 255, 1);

            .side-menu-close{
                display: flex;
                padding: 16px;
                justify-content: flex-end;

                i{
                    font-size: 24px;
                    color: #474D57;
                    cursor: pointer;
                }
            }

            .side-menu-main{

                ul{
                    border-bottom: solid 1px rgb(234, 236, 239);
                    li{
                        a{
                            display: flex;
                            font-size: 16px;
                            color: rgba(30, 35, 41);
                            align-items: center;
                            padding: 16px;
                            font-weight: 500;

                            svg{
                                width: 18px;
                                height: 18px;
                            }

                            span{
                                margin-left: 10px;
                            }

                            &:hover{
                                background: rgb(112, 112, 112, 0.25);
                                color: $color-1;

                                svg{
                                    color: $color-2;
                                    fill: $color-3;
                                }
                            }
                        }
                    }
                }

                .side-menu-btn{
                    padding: 8px 0px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

footer{   
    margin-top: 16px;

    .footer-menu{
        display: flex;
        justify-content: space-between;
        margin: 0 16px;

        .ft-menu-right{
           visibility: visible;

           ul{
            li{
                display: inline;
                font-size: 12px;
                font-weight: 500;
                margin-right: 8px;
                a{
                    color: $color-2;
                }
            }
           }
        }

        .ft-menu-social{
            ul{
                li{
                    a{
                        color: $color-2;

                        &:hover{
                            color: $color-1;
                        }
                    }
                }
            }
        }
    }

    .footer-copy{
        display: flex;
        align-items: center;
        border-top: solid 1px #efefef;
        font-size: 12px;
        color: #707070;
        line-height: 20px;
        padding-top: 24px;
        padding-bottom: 24px;
        margin-left: 16px;
        margin-right: 16px;
    }
}

// Styles pour les écrans d'ordinateur portables et de bureau
@media screen and (min-width: 768px) {
    html {
      font-size: 16px;
    }

    .col4{
        width: 33.33333%;
    }

    .col8{
        width: 66.66666%;
    }

    .app{
        .app-inner{
    
            .header{
                position: relative;
                flex-direction: column;
                padding: 0;
                margin: 0;
                height: auto;

                .header-desktop{
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    .header-top{
                        background-color: $color-1;
                        padding: .5rem 1rem;
                        display: flex;
                        justify-content: flex-end;
    
                        .nav{
                            display: flex;
                            li{
                                margin-left: .5rem;
    
                                a{
                                    font-size: .9rem;
                                    transition: color .3s ease;
                                    color: white;
    
                                    &:hover{
                                        color: $color-3;
                                    }
                                }
                            }
                        }
                    }

                    .header-main{
                        display: flex;
                        justify-content: space-between;
                        background-color: white;
                        border-bottom: 4px solid black;
                        padding: .5rem 1rem;

                        .header-logo{
                            padding: 0.5rem;

                            svg{
                                width: 190px;
                                height:52px;
                            }
                        }

                        .header-main-menu{
                            display: flex;
                            align-items: center;

                            .nav{
                                display: flex;
                                li{
                                    margin-left: 1rem;
                                    a{
                                        transition: color .3s ease-in-out;
                                        font-weight: 600;
                                        color: black;
                                        padding-bottom: 2rem;

                                        &:hover{
                                            color: $color-1;
                                            border-bottom: $color-3 solid 4px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .header-mobile{
                    display: none;
                }

            }

            .main{
                .hero{
                    margin-top: 0;
                }

                .section{
                    display: flex;
                    justify-content: center;

                    .section-inner{
                        width: 1024px;

                        .news{
                            flex-direction: row;

                            .news-item{
                                margin: 8px;
                            }
                        }

                        .section-help{
                            flex-direction: row;
                            justify-content: space-between;

                            .section-help-item{
                                width: 45%;
                            }
                        }

                        .section-video{
                            flex-direction: row;
                            justify-content: space-between;

                            .section-video-content{
                                width: 500px;
                                margin-right: 1rem;
                            }

                    
                        }
                    }

                    &.bac-etranger{
                        background: url('../images/map_monde.png') no-repeat center right #ea1d27;

                        .bac-etranger-inner{
                            width: 60%;
                            display: flex;

                            a{
                                text-transform: uppercase;
                                font-size: .875rem;
                                text-align: center;
                                color: $color-3;

                                .btn-bloc{
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    width: 100px;
                                    margin-right: 1rem;

                                    i{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 3rem;
                                        text-align: center;
                                        background-color: $color-3;
                                        padding: 1rem;
                                        border-radius: .5rem;
                                        height: 100px;
                                        color: $color-1;
                                        margin-bottom: 1rem;
                                    }
                                }

                                &:hover{
                                    color: white;
                                    
                                    i{
                                        background-color: white;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            footer{
                margin-top: 0;

                .footer-menu{
                    margin: 0;
                    padding: 15px 16px;
                    background: $color-2;

                    a{
                        color: white;

                        &:hover{
                            color: $color-3;
                        }
                    }
                }

                .footer-copy{
                    font-size: 11px;
                    margin: 0;
                    padding: 10px 16px;
                    background-color: #d1dcd5;
                }
            }
        
        }
    }
  }
  
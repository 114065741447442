footer{
    background: #212121;
    color: #9a9a9a;
    padding-top: 30px;
    margin-top: 50px;
}

.footer-bloc{
    margin: 15px;
}

.footer-bloc h3{
    padding-top: 15px;
    font-size: 22px;
    color: #EFEFEF;
}

.by-campusfaso{
    color: #CCC;
    font-size: .8rem;
}

footer .logo-site{
    margin: 32px 0px 32px 0px;
    font-size: 1.85rem;
    font-family: 'Kaushan Script', sans-serif;
    color: #efefef;
}

.small-text {
    font-size: 0.9rem;
}

.footer-bloc ul li a, .link-text{
    font-size: .9rem;
    color: #efefef;
}

.footer-bloc ul{
    list-style: none;
    padding: 0;
}

.footer-bloc ul li{
    line-height: 25px;
}

.footer-bloc ul li a:hover, .link-text:hover{
    color: #fbd400;
}

.footer-bloc strong{
    color: #EFEFEF;
}

ul.footer-social li a i {
    font-size: 2rem;
}

ul.inline-mode li {
    margin: 0 10px 10px 0;
    display: inline-block;
    border: 0;
    padding: 0;
}

li.payment-icon img{
    max-width: 55px !important;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

li.payment-icon img:hover{
    filter: none;
}

.copyright{
    background: #444;
}

.site-info{
    color: #EFEFEF;
    font-size: .8rem;
    text-align: center;
    padding: 20px 0;
}

.site-info a{
    color: #FFF;
}


$color-1: #ea1d27;
$color-2: #266c41;
$color-3: #fad115;

$color-text-1: #545454;
$color-text-dark: #222222;

$bg-color-2: #8A918C50;


$transition-duration: .3s;

/* Added by Marcus K. */
@import url(https://fonts.googleapis.com/css?family=Raleway:100,400,300,600);
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');

bodys {
    font-family: 'Raleway', sans-serif;
    font-size:14px;
    color:#ffffff;
    margin:0;
    padding: 0;
}


.sections {
    width: 100%;
    margin: 0;
    padding: 0;
}

.sections .section-inners {
    padding-left: 30px;
    padding-right: 30px;
}

.headers {
    /*position: absolute;*/
    text-align: center;
    top: 0px;
    z-index: 1000;
    color: #000000;
    width: 100%;
    height: 99px;
    background: #FFF;
    transition: background-color 200ms ease-in-out 0s;
    -o-transition: background-color 200ms ease-in-out 0s;
    -moz-transition: background-color 200ms ease-in-out 0s;
    -webkit-transition: background-color 200ms ease-in-out 0s;
}

.header-inners{
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
}
#main-nav{
    background: url('../images/bg_header.png');
    background-repeat: no-repeat;
    background-position: 30% 0;
}
.navbar-nav li a{
    font-size: 18px;
}

.navbar-nav li a.liens{
    font-size: 14px;
    font-weight: bold;
}

.logo {
    float: left;
    text-align: left;
    display: inline-block;
}

.logo a {
    width: 200px;
    display: block;
    margin-top: 75px;
    margin-bottom: 75px;
    transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
}

.logo a img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
    -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
    -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
    -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
}

.btn-primary {
    background: #fbd400;
    border: none;
    font-weight: 500;
    color: #000000;
}

.btn-primary:hover {
    background: #ed1c24;
    border: none;
    font-weight: 500;
    color: #FFF;
}

.sp-slide {
    font-family: 'Montserrat', sans-serif;
}

h3.sp-layer{
    font-size: 1.8rem;
    font-weight: 800;
}

.sp-overlay{
    position: absolute;
    background: rgba(0, 0, 0, .45);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

a.green{
    color: #119f4a;
    text-decoration: none;
}

a.green:hover{
    color: #ed1c24;
}

/* Version 2*/
.logo a {
    max-width: 100%;
    margin-top: 10px;
    transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
    -o-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
    -moz-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
    -webkit-transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s;
    position: relative;
}

.navbar-light .navbar-nav .nav-link {
    color: #212529;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #ed1c24;
}

   .act,.act a{
    border-bottom: 3px solid #00a141;
    font-weight: bold;
}
#slider{
    height: 400px;
    background: #EFEFEF;
}

.sections .title{
    font-size: 25px;
    font-weight: 700;
    color: #777;
    border-bottom: solid 1px #777;
    margin-bottom: 10px;
}

/*.box-items{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
}*/



.box-item-top-border {
    height: 6px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #00a141;
}




.box-shadow {
    box-shadow: 0 10px 20px 0 rgba(57, 58, 61, .15);
}

.box-item-content h4 {
    margin: 20px 0 10px;
    font-weight: 800;
}

.footers{
    background: #00a141;
    padding: 20px 0;
    color: #FFF;
}

.footer-armoiries {
    float: left;
    margin-right: 10px;
}

.footer-ministeres {
    float: left;
    width: 75%
}

.footer-ministeres h4 {
    font-weight: 600;
    font-size: 1rem;
    color: #EFEFEF;
    text-transform: uppercase;
}

.footer-ministeres p {
    font-weight: 300;
    font-size: .7rem;
    color: #FFF;
    text-transform: uppercase;
}

.footers h4.footer-box-title {
    font-size: 1.4rem;
    font-weight: 700;
}

.footers .box ul li a, .footer .small-text {
    font-size: 0.9rem;
}

.footers .box ul li a:hover {
    color: #fbd400;
}

.footer-socials {
    list-style: none;
}

.footer-socials li {
    display: inline-block;
}

.footer-socials li a {
    padding: 5px 8px;
    color: #FFF;
}

ul.footer-socials li a i {
    font-size: 2rem;
}

.sub-footers {
    background: #6A6A6A;
    color: #DDD;
    font-size: .75rem;
    text-align: center;
    padding: 4px 0;
}

/* BOX ITEM*/
.box-container {
    box-sizing: border-box;
    position: relative
}

.box-container:after {
    clear: both;
    content: "";
    display: block
}

@media screen and (min-width:768px) {
    .box-container {
        margin-left: auto;
        margin-right: auto;
        padding: 0 20px
    }
}

@media screen and (min-width:1024px) {
    .box-container {
        max-width: 984px;
        padding: 0 40px
    }
}

@media screen and (min-width:1200px) {
    .box-container {
        max-width: 1160px
    }
}

.box-items {
    list-style: none;
    margin: 0;
    padding: 0;
}

.box-item{
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 16px 20px;
}

.box-item-top-border {
    height: 6px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #00a141;
}

.box-item-content {
    padding: 20px;
}

.box-item-content {
    color: #000;
}

@media screen and (min-width:768px) {
    .box-container {
        padding: 0
    }

    .box-collapse {
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px)
    }
}

@media only screen and (min-width:768px) and (min-width:0px) {
    .box-content {
        width: calc(100% - 32px);
        float: left;
        margin-left: 16px
    }
}

@media only screen and (min-width:768px) and (min-width:480px) {
    .box-content {
        width: calc(100% - 32px);
        float: left;
        margin-left: 16px
    }
}

@media only screen and (min-width:768px) and (min-width:768px) {
    .box-content {
        width: calc(100% - 40px);
        float: left;
        margin-left: 20px
    }
}

@media only screen and (min-width:768px) and (min-width:1024px) {
    .box-content {
        width: calc(100% - 40px);
        float: left;
        margin-left: 20px
    }
}

@media only screen and (min-width:768px) and (min-width:1200px) {
    .box-content {
        width: calc(100% - 40px);
        float: left;
        margin-left: 20px
    }
}

@media screen and (min-width:768px) {
    .box-items {
        display: flex;
        flex-flow: row wrap
    }
}

@media only screen and (min-width:768px) and (min-width:0px) {
    .box-items>.box-item {
        width: calc(50% - 24px);
        float: left;
        margin-left: 16px
    }
}

@media only screen and (min-width:768px) and (min-width:480px) {
    .box-items>.box-item {
        width: calc(50% - 24px);
        float: left;
        margin-left: 16px
    }
}

@media only screen and (min-width:768px) and (min-width:768px) {
    .box-items>.box-item {
        width: calc(50% - 30px);
        float: left;
        margin-left: 20px
    }
}

@media only screen and (min-width:768px) and (min-width:1024px) {
    .box-items>.box-item {
        width: calc(50% - 30px);
        float: left;
        margin-left: 20px
    }
}

@media only screen and (min-width:768px) and (min-width:1200px) {
    .box-items>.box-item {
        width: calc(50% - 30px);
        float: left;
        margin-left: 20px
    }
}

@media screen and (min-width:768px) {
    .box-items>.box-item:nth-last-child(-n+2) {
        margin-bottom: 0
    }

    .box-items>.box-item:nth-child(-n+2) {
        margin-top: 0
    }
}

@media only screen and (min-width:768px) and (min-width:0px) {
    .box-items__3-column>.box-item {
        width: calc(33.33333% - 21.33333px);
        float: left;
        margin-left: 16px
    }
}

@media only screen and (min-width:768px) and (min-width:480px) {
    .box-items__3-column>.box-item {
        width: calc(33.33333% - 21.33333px);
        float: left;
        margin-left: 16px
    }
}

@media only screen and (min-width:768px) and (min-width:768px) {
    .box-items__3-column>.box-item {
        width: calc(33.33333% - 26.66667px);
        float: left;
        margin-left: 20px
    }
}

@media only screen and (min-width:768px) and (min-width:1024px) {
    .box-items__3-column>.box-item {
        width: calc(33.33333% - 26.66667px);
        float: left;
        margin-left: 20px
    }
}

@media only screen and (min-width:768px) and (min-width:1200px) {
    .box-items__3-column>.box-item {
        width: calc(33.33333% - 26.66667px);
        float: left;
        margin-left: 20px
    }
}

@media screen and (min-width:768px) {
    .box-items__3-column>.box-item:nth-last-child(-n+3) {
        margin-bottom: 0
    }

    .box-items__3-column>.box-item:nth-child(-n+3) {
        margin-top: 0
    }

    .box-item {
        margin: 20px 0 0
    }

    .box-item-content {
        padding: 40px
    }

    .box-item-list {
        margin-bottom: 40px
    }
}

.box-item a{
    transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
}

.box-item  a:hover{
    text-decoration: none;
}

.box-item a:hover > .box-item-content h4{
    color: #ed1c24;
}
/**/


.box-shadow {
    box-shadow: 0 10px 20px 0 rgba(57, 58, 61, .15);
}

.box-item-content h4 {
    margin: 20px 0 10px;
    font-weight: 800;
}
/* End of =>  Added by Marcus*/


/*slider*/
.c-red {
    color: #ed1c24;
}

.c-green {
    color: #00a141;
}

.c-yellow {
    color: #fbd400;
}

.bg-red {
    background-color: #ed1c24;
}

.bg-green {
    background-color: #00a141;
}

.bg-yellow {
    background-color: #fbd400;;
}
.bg-grey {
    background-color: #fafafa
}
.bg-white {
    background-color: #ffffff;
}
.bg-black {
    background-color:#000;
}
.no-padding {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.with-padding {
    padding: 5px 5px 5px 5px;
}

.couleur-titre-flash {
    color: #ed1c24;
}

.need-help {
    background: #ed1c24;
    color: #FFF;
    width: 100%;
    padding: 15px;
}

.need-help .help-item {
    background: rgba(255, 255, 255, .3);
    padding: 10px;
    margin-bottom: 10px;
}

.need-help .help-icon {
    float: left;
    width: 15%;
}

.need-help .help-text {
    float: left;
    width: 80%;
}

.need-help .help-text a {
    color: white;
    font-weight: bold;
}

/* ----------------------------- */

.link-text, .link-text:hover {
    color: #fff;
    text-decoration: none;
}

li {
    list-style-type: none;
}

.actu-content ul li:before{
    /*content: 'âœ“'; */
    content: '•';
}

/**/
.actu-img {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
    border:solid 1px #00a141;
    /*margin-left: .5rem;*/
}

.actu-content {
    color: #1a1a1a;
}

.actu-img img {
    width: 100%;
}

.bordure-bas-verte {
    border-bottom: 5px solid #00a141;
}

.bordure-bas-transparent {
    border-bottom: 5px solid #9ef0bf;
}


/* pour la zone d'information sous forme de trapÃ¨ze */
/* Construct the rectangles***********************************/
.planning h4{
    color: #ed1c24;
}

.planning p{
    color: #000;
}

.planning .active h4{
    color: #fbd400;
    font-weight: 600;
}

.planning .active p{
    color: #FFF;
}

.rrl {
    position: relative;
    height: 160px;
}

.rrc {
    position: relative;
    height: 160px;
}

.rrr {
    position: relative;
    height: 160px;
}

/* Add slanted edges*/
.rrl:after, .rrl:before, .rrc:after, .rrc:before, .rrr:before, .rrr:after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
}

.rr-left.active:after {
    right: 0;
    border-left: 100px solid #00a141;
    border-bottom: 160px solid #fff;
}

.rr-left:after {
    right: 0;
    border-left: 100px solid #fbd400;
    border-bottom: 160px solid #fff;
}

.rr-left.active:after {
    border-left: 100px solid #00a141;
    border-bottom: 160px solid #fff;
}

@media screen and (max-width: 768px) {
    .rr-left:after {
        right: 0;
        border-left: 100px solid #fbd400;
        border-bottom: 160px solid #fbd400;
    }

    .rr-left.active:after {
        right: 0;
        border-left: 100px solid #00a141;
        border-bottom: 160px solid #00a141;
    }


}

.rr-center.active:after {
    right: 0;
    border-left: 100px solid #00a141;
    border-bottom: 160px solid #fff;
}

.rr-center:after {
    right: 0;
    border-left: 100px solid #fbd400;
    border-bottom: 160px solid #fff;
}

@media screen and (max-width: 768px) {
    .rr-center.active:after {
        right: 0;
        border-left: 100px solid #00a141;
        border-bottom: 160px solid #00a141;
    }
    .rr-center:after {
        right: 0;
        border-left: 100px solid #fbd400;
        border-bottom: 160px solid #fbd400;
    }
}

.rr-center:before {
    left: -100px;
    border-right: 100px solid #fbd400;
    border-top: 160px solid transparent
}

.rr-center.active:before {
    left: -100px;
    border-right: 100px solid #00a141;
    border-top: 160px solid transparent
}


.rr-right.active:before {
    left: -100px;
    border-right: 100px solid #00a141;
    border-top: 160px solid transparent;
}

.rr-right:before {
    left: -100px;
    border-right: 100px solid #fbd400;
    border-top: 160px solid transparent;
}

@media screen and (max-width: 768px) {
    .separation{
        border-bottom :2px solid #ffffff;
    }
}

.btn-outline-green {
    color: #00a141;
    border-color: #00a141;
}
.btn-outline-green:hover {
    color:#fff;
    background-color:#00a141;
    border-color:#00a141;
}

/* Gestion de la coloration du plamnning*/
.planning .rrl, .planning .rrc, .planning .rrr{
    background: #fbd400;
}

.planning .active{
    background: #00a141;;
}




/**/


/* positionement des text sur les banniÃ¨res des pages du menu
 */

/* Top left text */
.top-left {
    position: absolute;
    top: 8px;
    left: 16px;
}


/* Nouvelles recherches */
.col-search{
    padding: 8px;
}

#div-search {
    display: inline-block;
    width: 100%;
    position: relative;
}

#formation-search {
    display: inline-block;
    border: 0;
    min-height: 40px;
    padding-left: 40px;
    position: relative;
    border-radius: 3px;
    background-color: #f2f6f7;
}

#searchLogo {
    position: absolute;
    top: 0;
    left: 0;
    color: #3d566e;
    width: 35px;
    text-align: center;
    padding-top: 12px;
    background-color: #d8e0e2;
    min-height: 40px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.dropdown-menu {
    background: #000;
    color: #FFF;
    border-radius: 0;
}

.dropdown-menu .dropdown-item {
    color: #FFF;
}

.dropdown-menu .dropdown-item:hover {
    background: #B4B4B4;
}

.notification{padding: 15px 15px 15px 70px; overflow: hidden; position: relative; margin-bottom: 10px; margin-top: 10px;}
.notification-error { background-color: #0fa04a;  color: #FFF;}
.notification-error::before {
        font-family: 'FontAwesome';
        font-size: 40px;
        display: block;
        position: absolute;
        left: 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
.notification-error::before {
        content: '\f05a';
    }
.notification-title {
        font-size: 1.4em;
        font-weight: bold;
    }
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
form.p-fluid{
    position: relative;
    /* overflow: hidden; */
}

.text-header{
    text-align: center;
}


.p-field{  
    /* margin-bottom: 20px; */
    /* height: 50px; */
    /* background-color: royalblue; */
}

.p-field input{
    height: 40px;
}

label{
    padding-top: 5px;
    /* margin:10px 3px 10px px; */
    margin-bottom: 3px;
    /* padding-left: 15px; */
    
    font-size: 14px;
    font-weight: 200;
    text-transform: capitalize;
}





.p-error{
    color: red;
    
}



.switch-btn{
    display: flex;
    grid-gap: 10px;
    justify-content: left;
    margin-bottom: 15px;
}



.tab{
    position: relative;
}

.btn-sum{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.p-flex{
    display: grid;
    grid-template-columns: 15fr 1fr;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
}

.btn-container {
    display: grid;
    grid-template-columns: auto auto auto;
    /* background-color: #9ccbb5; */
    
    
    margin-bottom: 25px;
    background-color: #2196F3;
}

.btn-tab{
    padding: 8px ;
}

.btn-tap:hover{
    background-color: #111;
}

/* .container{
    display: none;
}

.active{
    background-color: #ffffff !important;
    color: #111 !important;
    border: none !important;
    display: block;
} */

.special{
    position: absolute;
    bottom: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: flex-start;
}

body .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus{
    box-shadow: none;
}

.p-steps-number{
    border-radius: 50px !important;
    background-color: royalblue ;
    text-align: center !important;
    font-size: 24px !important;
}

.p-dialog-titlebar{
    padding:0px !important;
}

.p-steps-item .p-menuitem-link{
    padding-top: 0px  !important;
}

.radio-button{
    display: flex;
    /* margin-top: 30px; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    text-align: center;
    width: 175px;
}

.radio-button div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
}

.p-float{
   display: grid;
   grid-template-columns: 11fr 1fr;
   align-items:flex-end;
}
.p-dialog-content{
    height: 100% !important;
    /* overflow: hidden !important; */
}

.p-datatable .p-sortable-column.p-highlight{
    background-color:white !important;
}

.form-container{
    display: grid;
    grid-template-rows: 13fr 1fr;    
    /* background-color: tomato; */
    height: 67vh;
}

.fc-col-header-cell-cushion {
    color:white !important;
}

.full-calendar{
    /* width: 60vw; */
}

@media (min-width: 992px){
    .p-lg-5 {
        padding: 0px !important;
    }
}

.p-accordion .p-accordion-content{
    border: none !important;
}

span.detail-label{
    display: inline-block;
    font-weight: bolder;
    text-transform: uppercase;    
    margin-right: 15px;
    width: 200px;
    text-decoration: underline;

}

span.detail-point{
    font-size: large;
    display: inline-block;
    font-weight: bolder;
    margin-right: 10px;
}

h3.detail-header{
    text-align: center;
    background-color: #2196F3;
    margin-bottom: 15px;
    color: white;
    font-size: 22px;
    padding: 10px;
}
span.detail-value{
    display: inline-block;
    /* margin-right: 20px; */
    width: 200px;
}

.all-validation-items{
    display: flex;
    width: 40%;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px auto;
}

div.validation{
   width: 150px;
   height: 7px;
}

div.validation-container{
   
}
.validation-container span{
    /* font-size: 18px; */
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
}





/* ---------------Tchat ----------------- */

div.tchat-container{
    display: grid;
    grid-template-columns: 2fr 4fr;
    margin: 0px auto;
    width: 60vw;
    height: 80vh;
}

div.rigth-container{
    overflow: hidden scroll;
    background-color: azure;
    padding-left: 10px;
}

div.left-container{
    display: flex;
    flex-direction: column;
    overflow:  hidden scroll;
}
div.content-message{
    font-size: 16px;
    margin-left: 8px;
}

div.periode{
    display: flex;
    justify-content: center;
}

div.periode .heure{
    margin-left: 15px;
}

div.tchat-container div.infos-perso{
    display: flex;
    justify-content: space-evenly;
    margin:10px 0;
}

div.tchat-container .avatar-container{
    margin-right: 20px;
    width: 50px;
    height: 50px;
}

div.tchat-container .avatar{
    border-radius: 50px;
    width: 100%;
    width: 100%;
}

.p-dialog{
    max-width: 100vw !important;
    max-height: 100vh !important;
    margin: 0px !important;
    
}

.httperrors{
    font-size: large;
    line-height: 32px;
    word-spacing: 4px;
    font-family:Georgia, 'Times New Roman', Times, serif;
}

#data-table{
    margin-bottom: 20px;
}

#date-programmation{
    width: 60%;
}

button#btn a{
    text-decoration: none;
}
